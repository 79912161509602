import Grid from "@mui/material/Grid";
import {Link, Paper, Typography} from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const documentsArray = [
    {
        fileUrl: "/data/documents/Договор об оказании платных медицинских услуг.pdf",
        title: "Договор на оказание платных медицинских услуг (с Приложениями 1, 2)"
    },
    {
        fileUrl: "/data/documents/Л041-01162-50_00322818.pdf",
        title: "Лицензия на медицинскую деятельность ООО «Лео-Мед»"
    },
    {
        fileUrl: "/data/documents/Правила оказания платных медицинских услуг ООО Лео-Мед.pdf",
        title: "Правила оказания платных медицинских услуг"
    },
    {
        fileUrl: "/data/documents/Приложение 3. Перечень и стоимость.pdf",
        title: "Приложение 3 к Договору на оказание платных медицинских услуг"
    },
    {
        fileUrl: "/data/documents/Приложение 4. Акт приёма-передачи.pdf",
        title: "Приложение 4 к Договору на оказание платных медицинских услуг"
    },
    {
        fileUrl: "/data/documents/ul-1155045000868-20240618195021.pdf",
        title: "ОГРН ООО «Лео-Мед»"
    },
    {
        fileUrl: "/data/documents/poriadki-standarty-medpomoschi.pdf",
        title: "Стандарты и порядки оказания медицинской помощи, клинические рекомендации"
    },
    {
        fileUrl: "/data/documents/Постановление Правительства Российской Федерации от 11.05.2023 г. № 736.pdf",
        title: "Постановление от 11.05.2023 г. № 736 «Об утверждении Правил предоставления медицинскими организациями платных медицинских услуг»"
    }
]


const Document = ({fileUrl, title}) => {
    return (
        <Link href={fileUrl} underline="none" target="_blank" rel="noreferrer">
            <Paper elevation={3}
                   sx={{
                       padding: 2,
                       width: "100%",
                       height: "100%"
                   }}
            >
                <Grid container spacing={2} flex flexDirection="row" flexWrap="nowrap">
                    <Grid item flex flexDirection="column" width="fit-content" alignItems="center">
                        <InsertDriveFileIcon fontSize="large" color="primary"/>
                    </Grid>
                    <Grid item flex flexGrow={1}>
                        <Typography variant="body" component="div">
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Link>
    )
}


const Documents = () => {
    return (
        <Grid container display="flex" spacing={2}>

            {documentsArray.map(({fileUrl, title}, index) => (
                    <Grid item xs={12} sm={6} lg={4} key={index}>
                        <Document title={title} fileUrl={fileUrl}/>
                    </Grid>
                )
            )}

        </Grid>
    )
        ;
};

export default Documents;