import {lazy, StrictMode, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import {LinearProgress} from "@mui/material";
import Documents from "./Containers/Documents/Documents";
import DoctorListFallback from "./Containers/Doctors/DoctorListFallback";

const PriceList = lazy(() => import("./Containers/PriceList/PriceList"));
const Footer = lazy(() => import("./Containers/Footer"));
const Doctors = lazy(() => import("./Containers/Doctors/Doctors"));


const priceListRoot = document.getElementById("price-list");
// console.log('priceList:');
// console.log(priceListRoot);

if (priceListRoot) {
    const priceList = ReactDOM.createRoot(priceListRoot);
    priceList.render(
        <StrictMode>
            <ScopedCssBaseline>
                <Suspense fallback={<LinearProgress/>}>
                    <PriceList/>
                </Suspense>
            </ScopedCssBaseline>
        </StrictMode>
    );
}

const footerRoot = document.getElementById("footer");

if (footerRoot) {
    const footer = ReactDOM.createRoot(footerRoot);
    footer.render(
        <StrictMode>
            <ScopedCssBaseline>
                <Suspense fallback={<LinearProgress/>}>
                    <Footer/>
                </Suspense>
            </ScopedCssBaseline>
        </StrictMode>
    );
}

const docListRoot = document.getElementById('doc-list');

if (docListRoot) {
    const docList = ReactDOM.createRoot(docListRoot);
    docList.render(
        <StrictMode>
            <ScopedCssBaseline>
                <Suspense fallback={<DoctorListFallback/>}>
                    <Doctors/>
                </Suspense>
            </ScopedCssBaseline>
        </StrictMode>
    );
}

const documentsRoot = document.getElementById('documents');

if (documentsRoot) {
    const docs = ReactDOM.createRoot(documentsRoot);
    docs.render(
        <StrictMode>
            <ScopedCssBaseline>
                <Suspense fallback={<LinearProgress/>}>
                    <Documents/>
                </Suspense>
            </ScopedCssBaseline>
        </StrictMode>
    );
}