import React from 'react';
import Grid from "@mui/material/Grid";
import DoctorListFiltersFallback from "./DoctorListFiltersFallback";
import DoctorCardsFallback from "./DoctorCardsFallback";

const DoctorListFallback = () => {
    return (
        <Grid container gap={2}>
            <DoctorListFiltersFallback/>
            <DoctorCardsFallback/>
        </Grid>
    );
};

export default DoctorListFallback;