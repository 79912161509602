import React from 'react';
import Grid from "@mui/material/Grid";
import {Skeleton} from "@mui/material";

const DoctorCardsFallback = ({
                                 gridProps = {
                                     xs: 12,
                                     md: 6,
                                     lg: 4
                                 }
                             }) => {
    return (
        [1, 2, 3].map(i => (
            <Grid item flex {...gridProps} key={i}>
                <Skeleton width="100%" height={400} variant="rounded"/>
            </Grid>
        ))
    );
};

export default DoctorCardsFallback;