import Grid from "@mui/material/Grid";
import {Skeleton} from "@mui/material";

const DoctorListFiltersFallback = () => {
    return (
        <Grid item
              container
              width="100%" padding={2} gap={2}>
            <Grid item width="100%">
                <Skeleton width="100%" height={56} variant="rounded"/>
            </Grid>
        </Grid>
    );
};

export default DoctorListFiltersFallback;